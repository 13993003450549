import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { GoMarkGithub, GoMail, GoFilePdf } from 'react-icons/go';
import { FaLinkedin } from 'react-icons/fa';

function ZW1haWw(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
	if (ev.currentTarget) {
		ev.currentTarget.onclick = () => {
			const goto = `${atob('bWFpbHRv')}:${atob(
				'amVhbi1waGlsaXBwZS5zaW1hcmQlNDBueXhkZXZlbG9wcGVtZW50LmNvbQ=='
			)}`;
			window.location.href = decodeURIComponent(goto);
		};
	}
}

function Y3Y(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
	if (ev.currentTarget) {
		ev.currentTarget.onclick = () => {
			const goto = atob(
				`aHR0cHMlM0ElMkYlMkZjdi5ueXhkZXZlbG9wcGVtZW50LmNvbSUyRjAxN2MxOTRiZThiNGMxYTkyOWU2ODQ3N2MxYmRmOTAz`
			);
			window.location.href = decodeURIComponent(goto);
		};
	}
}

const About = () => {
	const { t } = useTranslation('about');

	return (
		<section id="about">
			<Container>
				<hr />
				<h1>{t('title')}</h1>
				<Row className="mt-5">
					<Col xs={2} className="d-flex justify-content-center">
						<svg className="align-self-center code-badge" opacity={0.64}>
							<use xlinkHref={`#csharp`} />
						</svg>
					</Col>
					<Col xs={2} className="d-flex justify-content-center">
						<svg className="align-self-center code-badge" opacity={0.64}>
							<use xlinkHref={`#html5`} />
						</svg>
					</Col>
					<Col xs={4} className="text-center">
						<Row>
							<Col>
								<img
									alt=""
									src="https://github.com/jpsimard-nyx.png?size=192"
									width="192"
									height="192"
									className="d-inline-block align-top"
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<span className="blue-text">Jean-Philippe</span> Simard
							</Col>
						</Row>
					</Col>
					<Col xs={2} className="d-flex justify-content-center">
						<svg className="align-self-center code-badge" opacity={0.64}>
							<use xlinkHref={`#nodejs`} />
						</svg>
					</Col>
					<Col xs={2} className="d-flex justify-content-center">
						<svg className="align-self-center code-badge" opacity={0.64}>
							<use xlinkHref={`#git`} />
						</svg>
					</Col>
				</Row>
				<Row className="contact">
					<Col
						xs={{ span: 1, offset: 4 }}
						className="d-flex justify-content-center"
					>
						<a href="https://www.linkedin.com/in/jphilippesimard">
							<button
								className="d-flex align-items-center justify-content-center btn btn-link"
								title="LinkedIn"
							>
								<FaLinkedin />
							</button>
						</a>
					</Col>
					<Col xs={1} className="d-flex justify-content-center">
						<a href="https://github.com/jpsimard-nyx">
							<button
								className="d-flex align-items-center justify-content-center btn btn-link"
								title="GitHub"
							>
								<GoMarkGithub />
							</button>
						</a>
					</Col>
					<Col xs={1} className="d-flex justify-content-center">
						<button
							onMouseEnter={ZW1haWw}
							className="d-flex align-items-center justify-content-center btn btn-link"
							title={t('mail')}
						>
							<GoMail />
						</button>
					</Col>
					<Col xs={1} className="d-flex justify-content-center">
						<button
							onMouseEnter={Y3Y}
							className="d-flex align-items-center justify-content-center btn btn-link"
							title={t('C.V.')}
						>
							<GoFilePdf />
						</button>
					</Col>
				</Row>
				<Row>
					<Col xs={{ span: 8, offset: 2 }} className="mt-3 mb-3 text-center">
						<p>{t('desc')}</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default About;

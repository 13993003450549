import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';

import Header from './header';

interface LayoutProps {
	children?: any;
}

const Layout = (props: LayoutProps) => {
	const { title } = useSiteMetadata();

	return (
		<>
			<Header siteTitle={title} />
			<main id="content" className="justify-content-md-center">{props.children}</main>
			<footer>
				{/* © {new Date().getFullYear()}, Built with
				{` `}
				<a href="https://www.gatsbyjs.org">Gatsby</a> */}
			</footer>
		</>
	);
};

export default Layout;

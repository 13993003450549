import React from 'react';
import '../i18n';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../components/about';
import Career from '../components/career';
import Skills from '../components/skills';
import Projects from '../components/projects';
import Banner from '../components/carousel';

const reqSvgs = require.context('../assets/svgs', true, /\.svg$/);
const paths = reqSvgs.keys();
const svgs = paths.map(path => reqSvgs(path));

const IndexPage = () => (
	<Layout>
		<SEO title="NYX Développement" />
		<Banner />
		<About />
		<Career />
		<Skills />
		<Projects />
	</Layout>
);

export default IndexPage;

import React from 'react';
import Container from 'react-bootstrap/Container';
import { Doughnut, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import 'chartjs-plugin-deferred';
import { useTranslation } from 'react-i18next';

Chart.pluginService.register({
	beforeDraw: function(chart: any) {
		// console.log(typeof chart);
		if (chart.config.options.elements.center) {
			//Get ctx from string
			var ctx = chart.chart.ctx;

			//Get options from the center object in options
			var centerConfig = chart.config.options.elements.center;
			var fontStyle = centerConfig.fontStyle || 'Arial';
			var txt = centerConfig.text;
			var color = centerConfig.color || '#000';
			var sidePadding = centerConfig.sidePadding || 20;
			var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
			//Start with a base font of 30px
			ctx.font = '30px ' + fontStyle;

			//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
			var stringWidth = ctx.measureText(txt).width;
			var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

			// Find out how much the font can grow in width.
			var widthRatio = elementWidth / stringWidth;
			var newFontSize = Math.floor(30 * widthRatio);
			var elementHeight = chart.innerRadius * 2;

			// Pick a new font size so it will not be larger than the height of label.
			var fontSizeToUse = Math.min(newFontSize, elementHeight);

			//Set font settings to draw it correctly.
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
			var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
			ctx.font = fontSizeToUse + 'px ' + fontStyle;
			ctx.fillStyle = color;

			//Draw text in center
			ctx.fillText(txt, centerX, centerY);
		}
	}
});

// backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
// hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
// C#, Javascript, SQL, NoSql, NodeJS, Genesys, DevOps, Web
const Skills = () => {
	const { t } = useTranslation('skills');

	return (
		<section id="skills">
			<Container>
				<hr />
				<Doughnut
				redraw={true}
					data={{
						datasets: [
							{
								data: [4.5, 4.5, 4.5, 4.5, 42, 7.5, 7.5, 6.5, 4, 3.5, 11],
								backgroundColor: [
									'#7D8CC4',
									'#7D8CC4',
									'#7D8CC4',
									'#7D8CC4',
									'transparent',
									'#495867',
									'#495867',
									'transparent',
									'#CC5803',
									'#CC5803',
									'transparent'
								],
								borderColor: [
									undefined,
									undefined,
									undefined,
									undefined,
									'transparent',
									undefined,
									undefined,
									'transparent',
									undefined,
									undefined,
									'transparent'
								],
								labels: [
									'HTML',
									'Javscript',
									'React',
									'NodeJS',
									'',
									'Kanban',
									'Scrum',
									'',
									'GitHub',
									'GitLab',
									''
								]
							},
							{
								data: [18, 18, 5, 5, 4, 5,5,15, 6.5, 7.5, 3.5, 7.5],
								backgroundColor: [
									'#7D8CC4',
									'#7D8CC4',
									'#7D8CC4',
									'#7D8CC4',
									'#7D8CC4',
									'#495867',
									'#495867',
									'#495867',
									'#CC5803',
									'#CC5803',
									'#CC5803',
									'#CC5803'
								],
								labels: [
									'Web',
									'C#',
									'Objective-C',
									'T-SQL',
									'SCXML',
									'Macrocope',
									'Waterfall',
									'Agile',
									'TeamCity',
									'Git',
									'Docker',
									'Azure DevOps'
								]
							},
							{
								data: [50, 25, 25],
								backgroundColor: ['#7D8CC4', '#495867', '#CC5803'],
								labels: [t('category.development'), t('category.methodology'), t('category.devops')]
							}
						]
					}}
					options={{
						responsive: true,
						legend: {
							display: false
						},
						animation: {
							animateRotate: true
						},
						tooltips: {
							callbacks: {
								label: function(tooltipItem: any, data: any) {
									if (
										data.datasets[tooltipItem.datasetIndex].labels[
											tooltipItem.index
										]
									)
										return data.datasets[tooltipItem.datasetIndex].labels[
											tooltipItem.index
										];
									this['_model'].backgroundColor = 'rgba(0,0,0,0)';
								}
							}
						},
						plugins: {
							deferred: {
								"xOffset": "50%",
								"delay": 250
							},
							labels: {
								render: (args: any) => `${args.dataset.labels[args.index]}`,
								arc: true,
								fontSize: 12,
								fontColor: '#FFF',
								textShadow: true
							}
						},
						elements: {
							center: {
								text: t('title'),
								color: '#c2bdb4', //Default black
								fontStyle: 'Helvetica' //Default Arial
								// sidePadding: 15 //Default 20 (as a percentage)
							},
							arc: {
								borderColor: '#363636'
							}
						}
					}}
				/>
			</Container>
		</section>
	);
};

export default Skills;

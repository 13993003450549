import React from 'react';
import Container from 'react-bootstrap/Container';
import {
	VerticalTimeline,
	VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useTranslation } from 'react-i18next';
import { GoBriefcase, GoMortarBoard } from 'react-icons/go';

const Career = () => {
	const { t } = useTranslation('career');
	const career: {
		title: string;
		date: string;
		symbol: string;
		job: string;
		desc: string;
	}[] = t('career', { returnObjects: true });

	return (
		<section id="career">
			<Container>
				<hr />
				<h1>{t('Carrière')}</h1>
				<VerticalTimeline>
					{Object.entries(career).map(([k, v]) => (
					<VerticalTimelineElement
						key={k}
						className={`vertical-timeline-element--${v.symbol}`}
						date={v.date}
						iconStyle={{ background: v.symbol === 'work' ? 'rgb(33, 150, 243)': 'rgb(233, 30, 99)', color: '#fff' }}
						icon={v.symbol === 'work' ? <GoBriefcase /> : <GoMortarBoard />}
					>
						<h4 className="vertical-timeline-element-title">
							{v.title}
						</h4>
						{/* <img
							className="ResumeWorkAndEducationBlock-picture"
							alt=""
							src=""
						></img> */}
						<h5 className="blue-text">{v.job}</h5>
						<p>
							{v.desc}
						</p>
					</VerticalTimelineElement>
					))}
					{/* <VerticalTimelineElement
						iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
						icon={<GoStar />}
					/> */}
				</VerticalTimeline>
			</Container>
		</section>
	);
};

export default Career;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Banner = () => {
	const { allFile } = useStaticQuery(
		graphql`
			query {
				allFile(
					sort: { fields: name, order: ASC }
					filter: { relativeDirectory: { eq: "carousel" } }
				) {
					edges {
						node {
							id
							name
							childImageSharp {
								fluid(
									maxWidth: 2560
									maxHeight: 500
									cropFocus: CENTER
									quality: 95
								) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		`
	);

	return (
		<Carousel>
			{allFile.edges.map(({ node }) => (
				<Carousel.Item key={node.id}>
					<Img fluid={node.childImageSharp.fluid} />
					{/* <Carousel.Caption>
						<h3>First slide label</h3>
						<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
					</Carousel.Caption> */}
				</Carousel.Item>
			))}
		</Carousel>
	);
};

export default Banner;

import React from 'react';
import Helmet from 'react-helmet';
import { useSiteMetadata } from '../hooks/use-site-metadata';

interface SEOProps {
	description?: string;
	lang?: string;
	meta?: any[];
	title: string;
}

function SEO({ description = '', lang = 'en', meta = [], title }: SEOProps) {
	const data = useSiteMetadata();

	const metaDescription = description || data.description;

	return (
		<Helmet
			htmlAttributes={{ lang }}
			title={title}
			titleTemplate={`%s | ${data.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: data.author
				},
				{
					name: `twitter:title`,
					content: title
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			].concat(meta)}
		/>
	);
}

export default SEO;

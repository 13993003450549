import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Projects = () => {
	const { t } = useTranslation('projects');
	const projects: {
		name: string;
		url: string;
		symbol?: string;
		img?: string;
		desc: string;
	}[] = t('projects', { returnObjects: true });

	const data = useStaticQuery(graphql`
	query {
		file(relativePath: { eq: "comprotel.png" }) {
			childImageSharp {
				# Specify a fixed image and fragment.
				# The default width is 400 pixels
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`)

	let i = 0;
	return (
		<section id="projects">
			<Container>
				<hr />
				<h1>{t('title')}</h1>
				<Row className="justify-content-center">
					{Object.entries(projects).map(([k, v]) => {
						let even = ++i % 2 === 0;
						return (
							<Col key={k} lg={6}>
								<Fade left={!even} right={even}>
									<Card className="mx-auto bg-transparent border-0 text-center">
										<Card.Img as="div" className="mx-auto">
											<a href={v.url}>
												{v.symbol ? (
													<svg>
														<use xlinkHref={`#${v.symbol}`} />
													</svg>
												): (
													<Img fluid={data.file.childImageSharp.fluid} style={{'display':'inline-block'}} />
													// <Card.Img variant="top" src={`images/${v.img}`} />
												)}
											</a>
										</Card.Img>
										<Card.Body>
											<Card.Title>
												<a href={v.url}>{v.name}</a>
											</Card.Title>
											<Card.Subtitle>{v.desc}</Card.Subtitle>
											{/* <Card.Text>{v.tech}</Card.Text> */}
										</Card.Body>
									</Card>
								</Fade>
							</Col>
						);
					})}
				</Row>
			</Container>
		</section>
	);
};

export default Projects;

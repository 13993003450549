import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();
	return (
		<>
			<div className="nav-link"  title="fr">
				<svg
					width={32}
					height={16}
					onClick={() => i18n.changeLanguage('fr')}
					className="align-self-center"
				>
					<use xlinkHref={`#Flag_of_Quebec`} />
				</svg>
			</div>
			<div className="nav-link" title="en">
				<svg
					width={32}
					height={16}
					onClick={() => i18n.changeLanguage('en')}
					className="align-self-center"
				>
					<use xlinkHref={`#Flag_of_Canada`} />
				</svg>
			</div>
		</>
	);
};

export default LanguageSwitcher;

// const LanguageSwitcher = ({ changeLng, lng, availableLngs }) => (
// 	const { i18n } = useTranslation('messages');

//   <ul style={{ listStyle: 'none' }}>
//     {availableLngs.map(value => (
//       <li key={value} style={{ display: 'inline' }}>
//         <button
//           style={{
//             background: 'rebeccapurple',
//             color: 'white',
//             textDecoration: value === lng ? 'underline' : 'none',
//           }}
//           onClick={() => changeLng(value)}
//         >
//           {value}
//         </button>
//       </li>
//     ))}
//   </ul>
// );

// export default props => (
//   <Language>{lngProps => <LanguageSwitcher {...props} {...lngProps} />}</Language>
// );

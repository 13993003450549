import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import path from 'path';
import merge from 'lodash.merge';

const reqLocales = require.context('./locales/', true, /\.json$/);
const paths = reqLocales.keys();
const resources = paths.reduce((prev, p) => {
	const lng = p.split(path.sep)[1];
	const ns = path.basename(p, path.extname(p));
	const locale = reqLocales(p);
	return merge(prev, { [lng]: { [ns]: locale } });
}, {});

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'fr',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;

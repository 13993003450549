// import { Link } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link, animateScroll as scroll } from 'react-scroll';
import LanguageSwitcher from './languageswitcher';
import throttle from 'lodash.throttle';
import { useTranslation } from 'react-i18next';

interface Props {
	siteTitle?: string;
}

const Header = ({ siteTitle = '' }: Props) => {
	const [height, setHeight] = useState(0);
	const [sticky, setSticky] = useState('');
	const ref = useRef(null);
	const { t } = useTranslation();

	// const [scrollY, setScrollY] = useState(0);

	function logit() {
		// setScrollY(window.pageYOffset);
		if (window.pageYOffset > height) {
			setSticky('sticky');
		} else {
			setSticky('');
		}
	}

	useEffect(() => {
		setHeight(ref.current.clientHeight);

		const throttled = throttle(logit, 250);
		window.addEventListener('scroll', throttled);
		return () => {
			window.removeEventListener('scroll', throttled);
		};
	});

	return (
		<header ref={ref} className="fixed-top {{}}">
			<Navbar bg="none" variant="dark" expand="lg" className={sticky}>
				<Container>
					<Navbar.Brand
						href="#"
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							scroll.scrollToTop();
						}}
						aria-label="Profile"
					>
						{/* <img
							alt=""
							src="https://github.com/jpsimard-nyx.png?size=60"
							width="60"
							height="60"
							className="d-inline-block align-top"
						/> */}
						{siteTitle}
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse
						id="responsive-navbar-nav"
						className="justify-content-end"
					>
						<Nav>
							<Link
								href="#"
								className="nav-link"
								activeClass="active"
								to="about"
								offset={-height}
								spy={true}
								smooth={true}
								duration={400}
							>
								{t('about:title')}
							</Link>
							<Link
								href="#"
								className="nav-link"
								activeClass="active"
								to="career"
								offset={-height}
								spy={true}
								smooth={true}
								duration={400}
							>
								{t('career:title')}
							</Link>
							<Link
								href="#"
								className="nav-link"
								activeClass="active"
								to="skills"
								offset={-height}
								spy={true}
								smooth={true}
								duration={400}
							>
								{t('skills:title')}
							</Link>
							<Link
								href="#"
								className="nav-link"
								activeClass="active"
								to="projects"
								offset={-height}
								spy={true}
								smooth={true}
								duration={400}
							>
								{t('projects:title')}
							</Link>
							<LanguageSwitcher />
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
